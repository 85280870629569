import { apiClient } from "../redux/service/ApiConfig";
import { PATH } from "../redux/service/apiConstant";

export async function getTransactionList({
  page = 1,
  startDate,
  endDate,
  cardholder,
  cardNickname,
  search,
}) {
  const params = new URLSearchParams({
    page: page,
    start_date: startDate,
    end_date: endDate,
  });

  if (cardholder) {
    params.append("card_holder", cardholder);
  }

  if (cardNickname) {
    params.append("card_nickname", cardNickname);
  }

  if (search) {
    params.append("search", search);
  }

  const res = await apiClient({
    url: `${PATH.transaction.getTransactionList}?${params.toString()}`,
    method: "GET",
  });

  const response = {
    data: {
      list: res?.data?.result?.data,
      page: res?.data?.result?.meta?.current_page,
      pageSize: res?.data?.result?.meta?.per_page,
    },
  };

  return response.data;
}

export async function exportTransactionList({
  startDate,
  endDate,
  cardholder,
  cardNickname,
  search,
}) {
  const params = new URLSearchParams({
    start_date: startDate,
    end_date: endDate,
  });

  if (cardholder) {
    params.append("card_holder", cardholder);
  }

  if (cardNickname) {
    params.append("card_nickname", cardNickname);
  }

  if (search) {
    params.append("search", search);
  }

  const res = await apiClient({
    url: `${PATH.transaction.exportTransactions}?${params.toString()}`,
    method: "GET",
  });

  return res.data?.result?.["download-link"];
}
