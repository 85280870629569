export * from "./theme";
export * from "./dynamic";
export * from "./featureFlags";
export * from "./navigationUrl";
export * from "./purchaseOrder";
export * from "./Categorisation";
export * from "./Acknowledgement";
export * from "./Cards";
export * from "./CardExpenses";
export * from "./Workflow";
export * from "./InvoiceManagement";
export * from "./Permissions";
export * from "./SupplierManagement";
export * from "./SmartApproval";
export * from "./ThresholdMatching";
export * from "./InvoiceMatching";
export * from "./Teams";
export * from "./table";
export * from "./Login";
export * from "./Registration";
export * from "./Configurations";
export * from "./CustomConditionalExpense";
export * from "./AuditLogModal";
export * from "./BroadCastChannel";
export * from "./ERPContactCreation.js";

export const BUTTON_LABELS = {
  CANCEL: "Cancel",
  SAVE: "Save",
  EDIT: "Edit",
  LOADING_SAVE: "Saving...",
  DRAFT: "Save as draft",
};

export const NOTIFICATION_TYPE = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error",
};

export const ERROR_MSG = {
  common: "Something went wrong, please try again",
  fetchingFail: "Failed to fetch data",
  OCR_UPLOAD_ERROR_MSG:
    "Could not read the uploaded file, please fill the form fields manually.",
};

export const INPUT_TYPES = {
  SELECT: "SELECT",
  TEXT: "TEXT",
};

export const CATEGORY_TYPES = {
  CUSTOM: "custom_categories",
};

export const CATEGORY_FIELD_TYPE_BE_KEY = {
  custom_categories: "customExpenses",
};

export const ORGANISATION_TYPE = {
  MODULR: "modulr",
  ANALYTICS: "analytics",
  OTHERS: { key: "others", SUB_TYPE: { PO: "PO", IM: "IM" } },
};

export const WORK_FLOW_TYPES = {
  AP: "account-payable-approval",
  PO: "purchase-order-approval",
  PR: "account-payable-payment-runs",
  CN: "credit-note-approval",
};

export const WORK_FLOW_TYPES_TO_TEXT = {
  AP: "invoice",
  PO: "purchase request",
  PR: "payment run",
  CN: "credit note",
};

export const MODULE_TYPES = {
  AP: "AP",
  PO: "PO",
  PR: "PR",
  CN: "CN",
};

export const TYPE_OF_WORKFLOW = {
  invoices: "account-payable-approval",
  po: "purchase-order-approval",
  pr: "account-payable-payment-runs",
  cn: "credit-note-approval",
};

export const SMART_APPROVAL_TABS_TOP_PANEL_NOTE = {
  invoices:
    "Create and manage workflows for invoices to be approved by users within your organisation",
  po: "Create and manage workflows for purchase order to be approved by users within your organisation",
  pr: "Create and manage workflows for payment runs to be approved by users within your organisation",
  cn: "Create and manage workflows for credit notes to be approved by users within your organisation",
};

export const SMART_APPROVAL_TABS_TOP_PANEL_HEADING = {
  invoices: "Workflows for approving submitted invoices",
  po: "Workflows for approving submitted purchase orders",
  pr: "Workflows for approving submitted payment runs",
  cn: "Workflows for approving submitted credit notes",
};

export const SMART_APPROVAL_TABS_TOP_PANEL_NOTE_2 = {
  invoices:
    "Create and manage workflows for invoices to be approved by users within your organisation",
  po: "Create and manage workflows for purchase requests to be approved by users within your organisation",
  pr: "Create and manage workflows for payment runs to be approved by users within your organisation",
  cn: "Create and manage workflows for credit notes to be approved by users within your organisation",
};

export const SMART_APPROVAL_TABS_TOP_PANEL_HEADING_2 = {
  invoices: "Workflows for approving submitted invoices",
  po: "Workflows for approving submitted purchase requests",
  pr: "Workflows for approving submitted payment runs",
  cn: "Workflows for approving submitted credit notes",
};

export const VIEW_MORE_BUTTON = "View more";
export const VIEW_LESS_BUTTON = "View less";

export const DUPLICATE_INVOICE = (selectedItem = []) => {
  return `Duplicate invoice${selectedItem.length > 1 ? "s" : ""}`;
};

export const CURRENCIES = {
  GBP: "GBP",
};

export const ERROR_MSG_CUSTOM_FIELD = "Integration is off";

//duplicate ID's need to get rid of one

export const AGILISYS_ORG_IDS = [
  "5f5f31c8-1713-4f24-af58-f4e090a77e33",
  "c9727927-3b7a-11ed-b538-a4fc776c6f93",
];

export const AGILISYS_ORG_IDS_WITH_DEV = [
  "fe1559dd-6ed4-449b-ade1-48e5fef69262", //devId
  "c9727927-3b7a-11ed-b538-a4fc776c6f93", //stageId
  "5f5f31c8-1713-4f24-af58-f4e090a77e33", //prodId
];

export const BLEEP_BOX_ORG_IDS = ["1200165d-45a8-11ef-9d5f-06a414f175cf"];

export const fileNameErrorMessage = `File name contains forbidden characters or words.`;

export const validateFileName = (fileName) => {
  const forbiddenCharacters = /[<>:"/\\|?*;#%&]/; // Define any characters you want to forbid from file name

  if (forbiddenCharacters.test(fileName)) {
    return false;
  }

  return true;
};

export const TRANSACTION_TABLE_COLUMNS = [
  { label: "Settled date", key: "date" },
  { label: "Type", key: "type" },
  { label: "Cardholder name", key: "card_holder_name" },
  { label: "Card nickname", key: "nickname" },
  { label: "Merchant", key: "merchant" },
  { label: "Receipt", key: "receipt_link" },
  { label: "Paid in", key: "paid_in" },
  { label: "Paid out", key: "paid_out" },
  { label: "Kloo balance", key: "gbp_balance" },
];
